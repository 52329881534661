import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Callout = ({ className, children }) => {
    const classes = ['c-callout', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return <div className={concatenatedClasses}>{children}</div>
}

Callout.propTypes = {
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Callout.defaultProps = {
    children: '<p>Callout content goes here</p>'
}

export default Callout
